import * as React from "react"


// markup
const NotFoundPage = () => {
  return (
    <main>
        <h1>404 Not Found</h1>
        <p>The server could not find what you were looking for</p>
    </main>
  )
}

export default NotFoundPage
